import { Modal } from "react-bootstrap";
import Spinner from "../spinner";
import { Formik } from "formik";
import React, { useState } from "react";
import styles from "../../pages/issuer/team/styles.module.css";
import Role from "./Role";
import { useToast } from "@chakra-ui/react";
import { invitationSchema } from "./schemas";
import { useIssuerOfferings } from "../../hooks/useOfferings";
import { useUserRoleUpdate } from "../../hooks/useIssuers";

const TeammateModal = ({ issuerId, show, onHide, user }) => {
  const { data: offeringsData, isLoading } = useIssuerOfferings(issuerId);
  const offerings = offeringsData?.offerings || [];

  const updateUserRole = useUserRoleUpdate();
  const toast = useToast();
  const [issuerRole, setIssuerRole] = useState(user?.roles?.issuer?.[issuerId]);
  const [offeringRoles, setOfferingRoles] = useState({});
  const onSubmit = async (values) => {
    try {
      await updateUserRole.mutateAsync({
        userId: user.id,
        issuerId,
        data: {
          role: issuerRole,
          offerings: offeringRoles,
        },
      });
    } catch (error) {
      console.error(error);
      if (error.response.status === 409) {
        toast({
          status: "error",
          description: "This user has already been added to this issuer",
        });
      }
    } finally {
      onHide();
    }
  };

  if (!issuerId || !user || !offerings) return null;

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-modal">
      <Modal.Body>
        <Spinner show={isLoading}>
          <h1>Manage Teammate</h1>
          <Formik
            enableReinitialize={true}
            initialValues={{
              email: user.email,
              role: issuerRole,
            }}
            validationSchema={invitationSchema}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}>
            {({ handleChange, handleSubmit, handleBlur, setFieldValue, values, errors }) => {
              return (
                <form className={styles.form} onSubmit={handleSubmit}>
                  <Role
                    name="issuer"
                    handleBlur={handleBlur}
                    handleChange={(value) => {
                      setIssuerRole(value.label.toLowerCase());
                    }}
                    errors={errors}
                    label="Select member's role"
                    values={values}
                    issuerId={issuerId}
                    setFieldValue={setFieldValue}
                    defaultValue={user.roles.issuer[issuerId]}
                  />
                  <div className={styles.tableLabel}>Allow access to the following offerings:</div>
                  <div className="card mt-3">
                    <div className="table-response">
                      <table className="table card-table">
                        <thead>
                          <tr>
                            <td>Offering</td>
                            <td>Role</td>
                          </tr>
                        </thead>
                        <tbody>
                          {offerings?.map((offering, index) => {
                            return (
                              <tr key={index}>
                                <td>{offering.name}</td>
                                <td className={styles.roleSelectCell}>
                                  <Role
                                    name={`offering-${offering.id}`}
                                    handleChange={(value) => {
                                      offeringRoles[offering.id] = value.label.toLowerCase();
                                      setOfferingRoles(offeringRoles);
                                    }}
                                    defaultValue={user.roles?.offering[offering?.id]}
                                    label={null}
                                    issuerId={issuerId}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Spinner show={false}>
                    <button className="btn btn-eq-primary" type="submit">
                      Update User Permissions
                    </button>
                  </Spinner>
                </form>
              );
            }}
          </Formik>
        </Spinner>
      </Modal.Body>
    </Modal>
  );
};

export default TeammateModal;
