import type React from "react";
import { useMemo } from "react";

import {
  hasIssuerRole,
  hasOfferingRole,
  isAdmin,
  isIssuerEditor,
  isIssuerOwner,
  isOfferingEditor,
  isOfferingOwner,
} from "@/helpers";
import type { TUserDTO } from "@/types/dto";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";

type TCanEditProps = {
  userRole?: "owner" | "editor" | "viewer";
  issuerId?: string;
  admin?: boolean;
  offeringId?: string;
  children: React.ReactNode;
};

export const canUserView = (
  user: Partial<Pick<TUserDTO, "roles" | "admin">>,
  options: Omit<TCanEditProps, "children">,
) => {
  const { issuerId, offeringId, userRole: role, admin = false } = options;

  if (!user) return false;
  if (admin && !isAdmin(user)) return false;
  if (isAdmin(user)) return true;

  if (hasIssuerRole(user, issuerId)) return true;
  if (hasOfferingRole(user, offeringId)) return true;

  return false;
};

export const canUserEdit = (
  user: Partial<Pick<TUserDTO, "roles" | "admin">>,
  options: Omit<TCanEditProps, "children">,
) => {
  const { issuerId, offeringId, userRole, admin = false } = options;

  if (!user) return false;
  if (admin && !isAdmin(user)) return false;
  if (isAdmin(user)) return true;

  if (issuerId) {
    if (userRole === "owner" && isIssuerOwner(user, issuerId)) return true;
    if (((!userRole || userRole === "editor") && isIssuerEditor(user, issuerId)) || isIssuerOwner(user, issuerId))
      return true;
  }

  if (offeringId) {
    if (userRole === "owner" && isOfferingOwner(user, offeringId)) return true;
    if (
      ((!userRole || userRole === "editor") && isOfferingEditor(user, offeringId)) ||
      isOfferingOwner(user, offeringId)
    )
      return true;
  }

  return false;
};

export const useCanEdit = (props: Omit<TCanEditProps, "children">) => {
  const { data: user }: { data: TUserDTO | unknown } = useCurrentUser();

  return useMemo(() => canUserEdit(user as TUserDTO, props), [props, user]);
};

export const useCanView = (props: Omit<TCanEditProps, "children">) => {
  const { data: user }: { data: TUserDTO | unknown } = useCurrentUser();

  return useMemo(() => canUserView(user as TUserDTO, props), [props, user]);
};

export const CanEdit: React.FC<TCanEditProps> = ({ userRole: role, issuerId, offeringId, admin, children }) => {
  const canEdit = useCanEdit({ userRole: role, issuerId, offeringId, admin });

  if (canEdit) return children;
  return null;
};

export const CanView: React.FC<TCanEditProps> = ({ userRole: role, issuerId, offeringId, admin, children }) => {
  const canView = useCanView({ userRole: role, issuerId, offeringId, admin });

  if (canView) return children;
  return null;
};
