import { VStack, useDisclosure } from "@chakra-ui/react";

import { InvestmentDetailsCard } from "@/areas/Investments/components/InvestmentDetailsCard";
import { InvestmentUserCard } from "@/areas/Investments/components/InvestmentUserCard";
import { useCanEdit } from "@/components/permissions/Can";
import ProfileCard from "@/components/vault/ProfileCard";
import type { TInvestmentDTO, TUserDTO, TVaultDTO } from "@/types/dto";

import { EditBasicInfoModal } from "../modals/EditBasicInfoModal";
import { EditContactInfoModal } from "../modals/EditContactInfoModal";

interface InvestmentSidebarCardsProps {
  investment?: TInvestmentDTO;
}

export function InvestmentSidebarCards({
  investment,
}: InvestmentSidebarCardsProps) {
  const basicInfoModal = useDisclosure();
  const contactInfoModal = useDisclosure();

  const user = investment?.user;

  const canEdit = useCanEdit({
    userRole: "owner",
    offeringId: investment?.offering_id,
  });

  if (!user || !investment) return null;

  const vault = investment.vault as TVaultDTO;

  const handleEdit = () => {
    // Handle edit action
  };

  return (
    <VStack spacing={4} align="stretch" flex={1}>
      <ProfileCard
        vault={vault}
        displayName={vault?.display_name}
        entityType={vault?.entity_type}
        hasAddress={
          !!(
            vault.address_street_1 ||
            vault.address_street_2 ||
            vault.address_city ||
            vault.address_state ||
            vault.address_postal_code ||
            vault.address_country_code
          )
        }
        address={{
          street: [vault.address_street_1, vault.address_street_2]
            .filter(Boolean)
            .join(", "),
          city: vault.address_city,
          state: vault.address_state,
          postal_code: vault.address_postal_code,
          country: vault.address_country_code,
        }}
        onEdit={handleEdit}
      />

      <InvestmentDetailsCard investment={investment} />
      <InvestmentUserCard
        user={investment.user as TUserDTO}
        canEdit={canEdit}
        onEdit={() => {
          basicInfoModal.onOpen();
        }}
      />

      {canEdit && (
        <>
          <EditBasicInfoModal
            modal={basicInfoModal}
            user={user}
            onSuccess={() => {
              refetchUser();
            }}
          />
          <EditContactInfoModal
            modal={contactInfoModal}
            user={user}
            onSuccess={() => {
              refetchUser();
            }}
          />
        </>
      )}
    </VStack>
  );
}
