import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";

import { isAdmin } from "../../../helpers";

const STATUS_ARRAY = ["Draft", "In Review", "Active", "Closed", "Terminated"];

const OfferingStatusMenu = ({ user, currentStatus, onSelect = () => {} }) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        size="sm"
        colorScheme="gray"
        rightIcon={<Icon.ChevronDown size="1em" />}>
        {currentStatus}
      </MenuButton>
      <MenuList>
        {STATUS_ARRAY.filter((status) => status !== currentStatus).map(
          (status) => {
            const canUpdate = status === "Active" ? isAdmin(user) : true;
            const icon = !canUpdate ? <Icon.Slash size="1em" /> : null;

            return (
              <MenuItem
                key={status}
                command={icon} // command is on the right, so better alignment
                isDisabled={!canUpdate}
                onClick={() =>
                  onSelect(status, status.toLowerCase().replace(/\s+/g, "_"))
                }>
                {status}
              </MenuItem>
            );
          },
        )}
      </MenuList>
    </Menu>
  );
};

export default OfferingStatusMenu;
