import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate, useParams } from "react-router";

import AWSClient from "@equidefi/portals/clients/AWSClient";
import AccreditationClient from "@equidefi/portals/clients/AccreditationClient";
import { EquidefiMarkdown } from "@equidefi/portals/components/Markdown";
import { useVerificationReport } from "@equidefi/portals/hooks/useAccreditation";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import { APPROVED, REJECTED } from "@equidefi/shared/constants/accreditation";
import { ALL_CONTENT_TYPES } from "@equidefi/shared/constants/files";
import {
  capitalizeEveryWord,
  formatPhoneNumber,
} from "@equidefi/shared/helpers/string";

import Spinner from "../../components/spinner";

import {
  useAccept,
  useReject,
  useVaultRecord,
} from "../../hooks/useAccreditation";
import Header from "../../layouts/header";
import "./style.css";

const Document = ({ file, number }) => {
  if (!file) return null;
  return (
    <tr>
      <td>File {number + 1}</td>
      <td className="align-right">
        <a
          href={file}
          className="btn btn-eq-primary"
          target="_blank"
          rel="noreferrer">
          Download
        </a>
      </td>
    </tr>
  );
};

const InvestorDocuments = (props) => {
  useDocumentTitle(["Review Investor Documents"]);

  const { id } = useParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const location = useLocation();
  const [downloadURL, setDownloadUrl] = useState();
  const [spinner, showSpinner] = useState(false);
  const [reviewed, setReviewed] = useState(null);
  const [file, setFile] = useState({});
  const [rejection, setRejection] = useState(null);

  const accreditationApi = useApi(AccreditationClient);

  const {
    data: vaultRecord,
    isLoading: isLoadingVault,
    isError: isVaultError,
  } = useVaultRecord(id, {
    onSuccess: async (data) => {
      const result = await accreditationApi.getDownloadUrls(id);
      setDownloadUrl(result);
    },
    onError: (error) => console.log("token error:", error),
  });

  const verificationReport = useVerificationReport(vaultRecord?.investment_id);
  const accept = useAccept(id);
  const reject = useReject(id);
  const navigate = useNavigate();

  const resetState = () => {
    setReviewed(null);
    setFile({});
    setRejection(null);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      showSpinner(true);
      const file = acceptedFiles[0];
      const filename = file.name.split(" ").join("_");
      const extension = filename.split(".").pop().toLowerCase();

      if (!Object.keys(ALL_CONTENT_TYPES).includes(extension)) {
        toast({
          type: "error",
          description: `You cannot upload a ${extension} file type, please upload one of these supported types: ${Object.keys(
            ALL_CONTENT_TYPES,
          ).join(", ")}`,
        });
        return;
      }

      const response = await verificationReport.mutateAsync({
        filename,
        extension,
      });
      const client = new AWSClient();
      client
        .s3Upload(
          response.signedUrl,
          acceptedFiles[0],
          ALL_CONTENT_TYPES[extension],
          file.size,
        )
        .then(() => {
          setFile({
            name: file.name,
            location: response.url,
          });
        })
        .catch((e) => {
          console.log(e);
          toast({
            status: "error",
            description: "Your file failed to upload!",
          });
        })
        .finally(() => {
          showSpinner(false);
        });
    },
    [toast, verificationReport],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    queryClient.invalidateQueries(["vault-record"]);
  }, [queryClient]);

  if (isLoadingVault || !downloadURL) {
    return <Spinner show={true} />;
  }

  if (isVaultError) {
    return (
      <div className="investor-documents">
        <div className="header">
          <div className="header-body">
            <h1 className="error">Permission Denied</h1>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <Header name="Review Investor Documents" />
      <br />
      <br />
      <div className="container investor-documents">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-6 name">
                <h2>{vaultRecord.display_name}</h2>
                <span className="entity-type">
                  (
                  {vaultRecord.entity_type
                    ? capitalizeEveryWord(vaultRecord.entity_type)
                    : "N/A"}
                  )
                </span>
              </div>
              <div className="col-6 status">
                <div className="actions">
                  <button
                    type="submit"
                    className="btn w-100 btn-eq-primary"
                    disabled={vaultRecord.status === APPROVED}
                    onClick={async () => {
                      setReviewed(APPROVED);
                    }}>
                    Accept
                  </button>
                  <button
                    type="submit"
                    className="btn w-100 btn-danger"
                    disabled={vaultRecord.status === REJECTED}
                    onClick={async () => {
                      setReviewed(REJECTED);
                    }}>
                    Reject
                  </button>
                </div>
              </div>
            </div>
            <div className="separated" />
            <div className="row">
              <div className="col-8">
                <div>{vaultRecord.address_street_1}</div>
                <div>{vaultRecord.address_street_2}</div>
                <div>
                  {vaultRecord.address_city}, {vaultRecord.address_state}{" "}
                  {vaultRecord.address_postal_code}
                </div>
                <div>{vaultRecord.address_country}</div>
                <br />
                <div>{vaultRecord.email}</div>
                <div>{formatPhoneNumber(vaultRecord.phone_number)}</div>
              </div>
              <div className="col-4 status" />
            </div>
          </div>
        </div>

        {vaultRecord.verification_report && (
          <div className="card">
            <div className="card-body">
              <div className="row">
                {vaultRecord.rejection_reason && (
                  <div className="col-12">
                    <h4>Rejection Reason:</h4>
                    <div className="rejection">
                      <EquidefiMarkdown>
                        {vaultRecord.rejection_reason}
                      </EquidefiMarkdown>
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <a
                    href={downloadURL.verification_report}
                    className="btn btn-eq-primary"
                    target="_blank"
                    rel="noreferrer">
                    Verification Report
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="title mb-4">
                  Accreditation Method:{" "}
                  <span className="bold">
                    {vaultRecord.accreditation_method
                      ? capitalizeEveryWord(
                          vaultRecord.accreditation_method?.replace("_", " "),
                        )
                      : "N/A"}{" "}
                    ({capitalizeEveryWord(vaultRecord.status)})
                  </span>
                </div>
                <table className="table card-table table-hover mb-0">
                  <tbody>
                    {Array.from({ length: 5 }).map((_, i) => (
                      <Document
                        number={i}
                        key={`doc-${i}`}
                        file={downloadURL[`doc_${i + 1}_file`]}
                        type={downloadURL[`doc_${i + 1}_type`]}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 utility-bill">
                <div className="title">Utility Bill/Address Verification</div>
                <div className="download">
                  <a
                    href={downloadURL.utility_bill}
                    className="btn btn-eq-primary"
                    target="_blank"
                    rel="noreferrer">
                    Download
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal centered size="m" show={!!reviewed} onHide={resetState}>
        <Modal.Header closeButton>
          <b>Please Confirm</b>
        </Modal.Header>
        <Modal.Body>
          <div className="accreditation-review">
            <div className="title">
              Are you sure you want to set this investors documents to{" "}
              <span className="bold">{reviewed?.toLowerCase()}</span>?
            </div>
            <div className="upload">
              <div className="message">
                Please upload your <b>Verification Report</b> document before
                confirming.
              </div>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <Spinner show={spinner} />
                <p>
                  {file.name ??
                    "Drag 'n' drop accreditation checklist file here, or click to select file"}
                </p>
              </div>
            </div>
            {reviewed === REJECTED ? (
              <div className="form-group">
                <label htmlFor="rejection_reason" className="mb-2">
                  Rejection reason (Markdown supported)
                </label>
                <textarea
                  id="rejection_reason"
                  rows={3}
                  name="rejection_reason"
                  className="form-control"
                  placeholder="Explain to the investor why their accreditation documents have been rejected. Markdown formatting is supported."
                  value={rejection}
                  onChange={(value) => setRejection(value.target.value)}
                />
                {rejection && (
                  <div className="mt-3">
                    <label htmlFor="preview">Preview:</label>
                    <div id="preview" className="p-2 border rounded">
                      <EquidefiMarkdown>{rejection}</EquidefiMarkdown>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            <div className="buttons">
              <button
                type="button"
                className={`btn w-100 btn-eq-confirm ${
                  !file.location ? "disabled" : null
                }`}
                disabled={
                  !file.location || (reviewed === REJECTED && !rejection)
                }
                onClick={async () => {
                  if (reviewed === APPROVED) {
                    await accept.mutateAsync({ file: file.location });
                    navigate("/accreditation");
                  } else if (reviewed === REJECTED) {
                    await reject.mutateAsync({
                      file: file.location,
                      reason: rejection,
                    });
                    navigate("/accreditation");
                  }
                }}>
                Yes
              </button>
              <button
                type="button"
                className="btn w-100 btn-eq-cancel"
                onClick={resetState}>
                No
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvestorDocuments;
