import { Button, Tooltip, useToast } from "@chakra-ui/react";

import { useStartSignatureRequest } from "@equidefi/portals/hooks/useSignatures";

const SetupAgreementButton = ({
  investmentId,
  tooltipLabel = undefined,
  isDisabled = false,
  onSetup = () => {},
  ...rest
}) => {
  const setupAgreement = useStartSignatureRequest();
  const toast = useToast();

  const handleConfirmSetup = async ({ reason }) => {
    try {
      await setupAgreement.mutateAsync({ investmentId, manualSetup: true });
      toast({
        status: "success",
        description:
          "The investment agreement has been set up for the investor.",
      });
      onSetup();
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description:
          e.response?.data?.errors?.join(", ") ??
          "Sorry, we could not set up this agreement. Our team has been notified.",
      });
    }
  };

  if (!investmentId) {
    return null;
  }

  return (
    <Tooltip
      label={isDisabled ? tooltipLabel : ""}
      hasArrow
      placement="top-start">
      <Button
        isLoading={setupAgreement.isLoading}
        onClick={handleConfirmSetup}
        isDisabled={isDisabled}
        colorScheme="purple"
        {...rest}>
        Set Up Agreement
      </Button>
    </Tooltip>
  );
};

export default SetupAgreementButton;
