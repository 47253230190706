import { Container, TabList, Tabs, VStack } from "@chakra-ui/react";
import { Outlet, useLocation } from "react-router";

import { TOfferingDTO } from "@/types/dto";
import { StatusBanner, Tab } from "@equidefi/ui";
import moment from "moment";
import React, { useMemo } from "react";
import { useOfferingContext } from "./OfferingContext";
import { OfferingHeaderCard } from "./components/OfferingHeaderCard";

export function OfferingNavigation(): React.JSX.Element {
  const location = useLocation();

  const defaultIndex = useMemo(() => {
    const tabs = ["closing", "tranches", "profiles", "details"];
    const index = tabs.findIndex((tab) => location.pathname.includes(tab)) + 1;

    return index;
  }, [location]);

  return (
    <Tabs mb={2} variant="equidefi" index={defaultIndex}>
      <TabList>
        <Tab href="./investments">Investments</Tab>
        <Tab href="./closing">Closing</Tab>
        <Tab href="./tranches">Closed Tranches</Tab>
        <Tab href="./profiles">Investors</Tab>
        <Tab href="./details">Offering Details</Tab>
      </TabList>
    </Tabs>
  );
}

const OfferingStatusBanner: React.FC<{ date?: string }> = ({ date: _date }) => {
  if (!_date) return null;

  const [now, date] = [moment(), moment(_date)];

  const isPassedEndDate = date.isBefore(now);
  const isNearingEndDate = now.isBetween(date.clone().subtract(2, "weeks"), date);

  let banner = null;
  if (isPassedEndDate) {
    banner = <StatusBanner status="error" title="Offering has passed end date" hideIcon centered uppercase />;
  }

  if (isNearingEndDate) {
    banner = <StatusBanner status="warning" title="Offering is nearing end date" hideIcon centered uppercase />;
  }

  return banner;
};

export default function OfferingAreaContainer(): React.JSX.Element {
  const { offering: offeringData } = useOfferingContext();
  const { offering } = (offeringData || {}) as { offering?: TOfferingDTO };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={4} align="stretch">
        <OfferingHeaderCard offering={offering} />
        <OfferingNavigation />
        <OfferingStatusBanner date={offering?.termination_date} />
        <Outlet />
      </VStack>
    </Container>
  );
}
