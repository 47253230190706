import { SectionEditIconButton, SectionMiniTitle } from "@/areas/Investments/components/Sections";
import { CanEdit } from "@/components/permissions/Can";
import type { TUserDTO } from "@/types/dto";
import { Card, CardBody, Flex, HStack, Text } from "@chakra-ui/react";
import CopyableIcon from "@equidefi/portals/components/CopyableIcon";
import { Icon } from "@equidefi/ui/icon";
import type React from "react";

type User = Partial<Pick<TUserDTO, "first_name" | "last_name" | "email" | "phone">>;

interface InvestmentUserCardProps {
  user?: User;
  canEdit?: boolean;
  onEdit?: () => void;
}

export const InvestmentUserCard: React.FC<InvestmentUserCardProps> = ({ user, canEdit, onEdit }) => {
  return (
    <Card>
      <CardBody>
        <HStack justify="space-between">
          <SectionMiniTitle mb="0">User Account</SectionMiniTitle>

          <CanEdit admin>
            <SectionEditIconButton onClick={onEdit} />
          </CanEdit>
        </HStack>

        <Flex align="center" gap={3} mb={2} height="2em">
          <Icon.Mail size="1.2em" />
          <CopyableIcon text={user?.email} iconSize="1.2em" iconColor="blue" />
        </Flex>

        <Flex align="center" gap={3} height="2em">
          <Icon.Phone size="1.2em" />
          {user?.phone ? (
            <CopyableIcon text={user?.phone} iconSize="1.2em" iconColor="blue" />
          ) : (
            <Text color="gray.500" pt={2}>
              N/A
            </Text>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
};
