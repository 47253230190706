import {
  Box,
  type BoxProps,
  Button,
  Flex,
  HStack,
  Link,
  type StackProps,
  Text,
  type TextProps,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import type React from "react";
import { useCallback } from "react";

import { EquidefiMarkdown } from "@equidefi/portals/components/Markdown";
import { USDFormatter } from "@equidefi/portals/helpers/format";
import { capitalizeEveryWord } from "@equidefi/shared/helpers/string";
import { Icon } from "@equidefi/ui/icon";

import { useInvestmentContext } from "@/areas/Investments/InvestmentContext";
import {
  CancelManualPaymentButton,
  CaptureStripePaymentButton,
  ConfirmManualPaymentButton,
  RefundPaymentButton,
  TerminateStripePaymentButton,
} from "@/areas/Investments/components/InvestmentPaymentButtons";
import { ButtonActionGroup } from "@/components/ButtonActionGroup";
import { CanEdit } from "@/components/permissions/Can";
import type { TInvestmentDTO, TPaymentDTO } from "@/types/dto";

export function stripePaymentUrl(
  paymentIntentId: string,
  stripeAccountId?: string,
) {
  const base = new URL("https://dashboard.stripe.com");

  const prefix = stripeAccountId ? `connect/accounts/${stripeAccountId}` : "";

  return new URL(`${prefix}/payments/${paymentIntentId}`, base);
}

type InvestmentPaymentProps = {
  payment: TPaymentDTO;
  investment: TInvestmentDTO;
  buttons?: React.ReactNode;
};

const PaymentStatusColors: Record<string, { color?: string; bg?: string }> = {
  succeeded: { bg: "green.500" },
  refunded: { bg: "red.500" },
  canceled: { bg: "red.500" },
  requires_capture: { bg: "yellow.500" },
  requires_action: { bg: "yellow.500" },
  processing: { bg: "yellow.500" },
  created: { bg: "yellow.500 " },
};

const InvestmentPaymentStatusIcon: React.FC<
  Pick<InvestmentPaymentProps, "payment">
> = ({ payment }) => {
  return (
    <Tooltip
      placement="bottom"
      label={capitalizeEveryWord(String(payment.status).split(/_/g).join(" "))}>
      <Flex
        boxSize="33px"
        align="center"
        justify="center"
        borderRadius="md"
        color={PaymentStatusColors[payment.status]?.color ?? "white"}
        bg={PaymentStatusColors[payment.status]?.bg ?? "gray.50"}>
        <Icon.DollarSign size="1.2em" />
      </Flex>
    </Tooltip>
  );
};

const InvestmentPaymentContainer: React.FC<StackProps> = (props) => (
  <HStack
    align="center"
    spacing={4}
    mb={0}
    alignItems="flex-start"
    {...props}
  />
);

const InvestmentPaymentBody: React.FC<BoxProps> = (props) => <Box {...props} />;
const InvestmentPaymentAmount: React.FC<
  { amount?: string | number; status?: string } & TextProps
> = ({ amount, status, ...props }) => {
  return (
    <Text
      mb="3"
      fontSize="xl"
      fontWeight="bold"
      my="auto"
      textDecoration={
        ["canceled", "refunded"].includes(status as string)
          ? "line-through"
          : "none"
      }
      {...props}>
      {USDFormatter(Number(amount ?? 0))}
    </Text>
  );
};

const InvestmentPaymentType: React.FC<{
  type: string | null | undefined;
  after?: React.ReactNode;
}> = (props) => {
  const { type, after = null } = props;

  const determineType = useCallback(
    (paymentType: string | undefined | null) => {
      const map = new Map([
        ["cashapp", "Cash App"],
        ["us_bank_account", "ACH"],
      ]);

      // legacy payments don't have a payment type
      if (typeof paymentType === "undefined" || paymentType === null) {
        return after ? "Stripe" : "Manual";
      }

      return map.has(paymentType)
        ? map.get(paymentType)
        : capitalizeEveryWord(paymentType);
    },
    [after],
  );

  return (
    <Text textStyle="h3" fontWeight="bold" mb="1">
      {determineType(type)} Payment
      {after}
    </Text>
  );
};

const InvestmentPaymentIndication: React.FC<
  { payment: TPaymentDTO; investment: TInvestmentDTO } & TextProps
> = (props) => {
  const { payment, investment, ...rest } = props;

  let text: string;
  let name: string;
  let date: moment.Moment;

  switch (payment.status) {
    case "succeeded":
      text = "Approved";
      date = moment(payment.approval_date);
      name = payment?.approver?.full_name || "N/A";
      break;
    case "refunded":
    case "canceled":
      text = capitalizeEveryWord(payment.status);
      date = moment(payment.refund_date);
      name = payment?.refunder?.full_name || "N/A";
      break;
    default:
      text = "Indicated";
      date = moment(payment.create_date);
      name = investment?.vault?.display_name || "N/A";
      break;
  }

  return (
    <Text color="gray.600" fontSize="sm" mb="0" {...rest}>
      {text} by {name} on{" "}
      <Tooltip label={date.format("lll")}>
        {date.format("MMM Do, YYYY")}
      </Tooltip>
    </Text>
  );
};

interface TPaymentNotesProps extends BoxProps {
  isVisible?: boolean;
  title?: string;
  content?: string;
}

const InvestmentPaymentNotes: React.FC<TPaymentNotesProps> = (props) => {
  const { isVisible = true, title, content, ...rest } = props;
  if (!isVisible) return null;

  return (
    <Box textStyle="body2" mb="2">
      {title && (
        <Text fontWeight="bold" mb="1">
          {title}
        </Text>
      )}

      <EquidefiMarkdown color="gray.600" {...rest}>
        {content}
      </EquidefiMarkdown>
    </Box>
  );
};

type InvestmentPaymentBaseProps = InvestmentPaymentProps;

const InvestmentManualPayment: React.FC<InvestmentPaymentBaseProps> = ({
  payment,
  investment,
}) => {
  const { refetch } = useInvestmentContext();

  return (
    <InvestmentPaymentContainer>
      <InvestmentPaymentStatusIcon payment={payment} />
      <InvestmentPaymentBody>
        <InvestmentPaymentAmount
          amount={payment.amount}
          status={payment.status}
        />

        <VStack mt="3" align="flex-start" spacing={0}>
          <InvestmentPaymentType type={payment.payment_type} />
          <InvestmentPaymentIndication
            payment={payment}
            investment={investment}
          />
          <InvestmentPaymentNotes content={payment.notes} />
          <InvestmentPaymentNotes
            isVisible={payment.status === "refunded"}
            title="Refund Notes"
            content={payment.refund_notes}
          />
        </VStack>
        <CanEdit
          issuerId={investment.offering.issuer_id}
          offeringId={investment.offering_id}>
          <ButtonActionGroup offset={-3} size="sm">
            <RefundPaymentButton payment={payment} onSubmit={() => refetch()} />
            <ConfirmManualPaymentButton
              payment={payment}
              onSubmit={() => refetch()}
            />
            <CancelManualPaymentButton
              payment={payment}
              onSubmit={() => refetch()}
            />
          </ButtonActionGroup>
        </CanEdit>
      </InvestmentPaymentBody>
    </InvestmentPaymentContainer>
  );
};

const InvestmentStripePayment: React.FC<InvestmentPaymentBaseProps> = ({
  payment,
  investment,
}) => {
  const { refetch } = useInvestmentContext();

  return (
    <InvestmentPaymentContainer>
      <InvestmentPaymentStatusIcon payment={payment} />
      <InvestmentPaymentBody>
        <InvestmentPaymentAmount amount={payment.amount} />

        <VStack mt="3" align="flex-start" spacing={0}>
          <InvestmentPaymentType
            type={payment.payment_type}
            after={
              <Button
                as={Link}
                leftIcon={<Icon.ExternalLink size="1em" />}
                colorScheme="gray"
                variant="ghost"
                ml={2}
                mt={-1}
                size="xs"
                aria-label="View payment on Stripe"
                href={stripePaymentUrl(
                  payment.payment_intent_id,
                  investment?.offering?.stripe_account_id,
                ).toString()}
                target="_blank"
                rel="noreferrer noopener">
                Stripe
              </Button>
            }
          />
          <Text fontWeight="medium" mb={0}>
            {payment.payment_method_display}
          </Text>
          <InvestmentPaymentIndication
            payment={payment}
            investment={investment}
          />
          <InvestmentPaymentNotes content={payment.notes} />
          <InvestmentPaymentNotes
            isVisible={payment.status === "refunded"}
            title="Refund Notes"
            content={payment.refund_notes}
          />
        </VStack>
        <CanEdit
          issuerId={investment.offering.issuer_id}
          offeringId={investment.offering_id}>
          <ButtonActionGroup offset={-3} size="sm">
            <RefundPaymentButton payment={payment} onSubmit={refetch} />
            <CaptureStripePaymentButton payment={payment} onSubmit={refetch} />
            <TerminateStripePaymentButton
              payment={payment}
              onSubmit={refetch}
            />
          </ButtonActionGroup>
        </CanEdit>
      </InvestmentPaymentBody>
    </InvestmentPaymentContainer>
  );
};

export const InvestmentPayment: React.FC<InvestmentPaymentBaseProps> = ({
  payment,
  investment,
}) => {
  const PaymentComponent =
    payment.category === "MANUAL"
      ? InvestmentManualPayment
      : InvestmentStripePayment;

  return <PaymentComponent payment={payment} investment={investment} />;
};
