import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router";
type TQueryParamType = "string" | "number" | "boolean" | "array";

type TQueryParamConfig<DefaultValue = unknown> = {
  defaultValue?: DefaultValue;
  type?: TQueryParamType;
};

type TQueryParamDefinition = Record<string, TQueryParamConfig>;

const castValue = (value: unknown, type: TQueryParamType) => {
  if (type === "string") {
    return value;
  } else if (type === "number") {
    return Number(value);
  } else if (type === "boolean") {
    return `${value}`.toLocaleLowerCase() === "true";
  }

  return value;
};

export const useQueryParams = (params: TQueryParamDefinition) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const values = useMemo(() => {
    const entries = Array.from(searchParams.entries());

    return entries.reduce((memo, [key, value]) => {
      const type = params[key]?.type ?? "string";
      memo[key] = castValue(value, type);
      return memo;
    }, {});
  }, [searchParams]);

  const get = useCallback(
    (key: string) => {
      return values[key] ?? params[key]?.defaultValue;
    },
    [values],
  );

  const set = useCallback(
    (values: Record<string, unknown>) => {
      for (const key in values) {
        const v = values[key];

        if (typeof v === "undefined" || v === null) {
          searchParams.delete(key);
        } else {
          searchParams.set(key, values[key] as string);
        }
      }

      setSearchParams(searchParams);
    },
    [searchParams],
  );

  const remove = useCallback(
    (keys: string[]) => {
      for (const key of keys) {
        searchParams.delete(key);
      }
      setSearchParams(searchParams);
    },
    [searchParams],
  );

  const removeAll = useCallback(() => {
    for (const key of Array.from(searchParams.keys())) {
      searchParams.delete(key);
    }
    setSearchParams(searchParams);
  }, [searchParams]);

  const has = useCallback(
    (key: string) => searchParams.has(key),
    [searchParams],
  );

  return {
    values,
    get,
    set,
    remove,
    has,
    removeAll,
  };
};
