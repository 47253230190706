import {
  Badge,
  BadgeProps,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import React, { useMemo } from "react";

import {
  HeaderAvatar,
  HeaderBreadcrumb,
  HeaderCard,
} from "@/components/nav/HeaderCard";
import { ShareUrlButton } from "@/components/offerings/ShareUrlButton";
import { CONFIG } from "@/constants/config";
import { ButtonLink } from "@equidefi/portals/components/ButtonLink";
import { offeringIsActive } from "@equidefi/shared";
import { capitalizeEveryWord } from "@equidefi/shared/helpers/string";
import { Icon } from "@equidefi/ui/icon";

interface OfferingHeaderCardProps {
  offering: any;
}

const OfferingExemptionBadge: React.FC<BadgeProps & { type: string }> = ({
  type,
  ...rest
}) => {
  return (
    <Badge textTransform="inherit" colorScheme="green" {...rest}>
      {capitalizeEveryWord(String(type).toLowerCase().replace(/_/g, " "))}
    </Badge>
  );
};

export function OfferingHeaderCard({
  offering,
}: OfferingHeaderCardProps): React.JSX.Element {
  const { issuer, name, exemption_type, id: offeringId } = offering || {};
  const { name: issuerName, photo_url: issuerPhotoUrl } = issuer || {};

  const shareUrl = useMemo(() => {
    if (offering?.slug) {
      return new URL(
        `/offerings/${offering.slug}/registration`,
        CONFIG.EQ_APP_URL,
      );
    }
  }, [offering?.slug]);

  return (
    <HeaderCard>
      <Flex
        direction={{ base: "column", md: "row" }}
        gap={{ base: 5, md: 0 }}
        justify="space-between"
        align="center">
        <Grid
          templateRows="auto auto"
          templateColumns="repeat(8, 1fr)"
          gap={2}
          minH="70px">
          <GridItem w="80px" alignItems="center" rowSpan={2}>
            <HeaderAvatar
              url={issuerPhotoUrl}
              alt={`${issuerName} Logo`}
              boxSize="70px"
            />
          </GridItem>
          <GridItem alignContent="center" colSpan={6}>
            <HeaderBreadcrumb
              crumbs={{ [issuerName]: `/v2/issuers/${offering?.issuer_id}` }}
            />
          </GridItem>
          <GridItem alignContent="center" colSpan={6}>
            <Flex align="flex-start" gap={3} flexWrap="wrap">
              <Heading
                as="h1"
                fontSize={{ base: "xl", md: "2xl" }}
                lineHeight={1.2}
                mb={0}
                mt={-1}>
                {name}
              </Heading>
              <OfferingExemptionBadge mt={"0.5"} type={exemption_type} />
            </Flex>
          </GridItem>
        </Grid>
        <ButtonGroup variant="ghost" colorScheme="gray" size="sm" gap={1}>
          {offeringIsActive(offering) && (
            <ShareUrlButton
              leftIcon={<Icon.Clipboard size="1.4em" />}
              p="5"
              content={String(shareUrl)}>
              Investor URL
            </ShareUrlButton>
          )}
          <ButtonLink
            to="./admin"
            p="5"
            leftIcon={<Icon.Settings size="1.4em" />}>
            Offering Settings
          </ButtonLink>
        </ButtonGroup>
      </Flex>
    </HeaderCard>
  );
}
