import { Suspense, lazy } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router";

import Spinner from "@/components/spinner";
import { AppLayout } from "@/layouts/AppLayout";
import { AuthLayout } from "@/layouts/AuthLayout";
import { PrivateRoute, PublicRoute } from "@equidefi/portals/components/Routes";

import { AdminLayout } from "@/layouts/admin";
import { PublicLayout } from "@/layouts/public";

import CompleteProfile from "./pages/auth/completeProfile";
import ForgotLegacy from "./pages/auth/forgot";
import LoginLegacy from "./pages/auth/login";
import MFALegacy from "./pages/auth/mfa";
import ResetLegacy from "./pages/auth/reset";

import InquiriesList from "./pages/inquiries";
import VaultList from "./pages/vault";

import OfferingListLegacy from "./pages/offering";
import OfferingCreate from "./pages/offering/create";
import OfferingOverview from "./pages/offering/overview";
import OfferingWizard from "./pages/offering/wizard";

import Investment from "./pages/offering/InvestmentOverview";
import OfferingInvestmentsLegacy from "./pages/offering/investors";

import BrokerListLegacy from "./pages/brokers";
import BrokerEditorLegacy from "./pages/brokers/editor";

import OfferingAgreements from "./pages/offering/OfferingAgreements";
import OfferingAgreementsLegacy from "./pages/offering/agreements";
import OfferingBrokers from "./pages/offering/brokers";
import OfferingDocuments from "./pages/offering/documents";
import OfferingSettings from "./pages/offering/settings";

import MissingPage from "./pages/MissingPage";
import MyProfile from "./pages/auth/profile";
import InvoiceList from "./pages/invoices";
import { Invoice } from "./pages/invoices/Invoice";
import Payment from "./pages/invoices/Payment";
import Success from "./pages/invoices/Success";
import IssuerListLegacy from "./pages/issuer";
import IssuerEditor from "./pages/issuer/editor";
import IssuerOfferings from "./pages/issuer/offerings";
import IssuerTeam from "./pages/issuer/team";

import InvestmentContextProvider from "@/areas/Investments/InvestmentContext";
import OfferingContextProvider from "@/areas/Offerings/OfferingContext";
import IssuerContextProvider from "@/areas/Issuers/IssuerContext";
import InvestmentAreaContainer from "@/areas/Investments/InvestmentAreaContainer";
import OfferingAreaContainer from "@/areas/Offerings/OfferingAreaContainer";

import { IssuerChakraProvider } from "@/providers";
import { EquidefiTheme, V2Theme } from "@equidefi/ui/theme";
import ScrollToTop from "./components/ScrollToTop";
import AccreditationList from "./pages/accreditation";
import InvestorDocuments from "./pages/accreditation/InvestorDocuments";
import Roles from "./pages/issuer/roles";
import AcceptInvitation from "./pages/issuer/team/AcceptInvitation";
import Invitations from "./pages/issuer/team/Invitations";
import OfferingAdmin from "./pages/offering/Admin";
import OfferingTrancheLegacy from "./pages/offering/Tranche";
import OfferingTraunchesLegacy from "./pages/offering/Traunches";
const VaultEdit = lazy(() => import("@/pages/vault/Edit"));
const VaultOverview = lazy(() => import("@/pages/vault/Overview"));
const VaultProfile = lazy(() => import("@/pages/vault/Profile"));
const VaultAddress = lazy(() => import("@/pages/vault/Address"));
const VaultEntity = lazy(() => import("@/pages/vault/Entity"));
const VaultMetadata = lazy(() => import("@/pages/vault/Metadata"));

const VaultInvestors = lazy(() => import("@/areas/Vault/InvestorsList"));

const Forgot = lazy(() => import("@/areas/Auth/Forgot"));
const Login = lazy(() => import("@/areas/Auth/Login"));
const MFA = lazy(() => import("@/areas/Auth/MFA"));
const Reset = lazy(() => import("@/areas/Auth/Reset"));

const BrokerList = lazy(() => import("@/areas/Brokers/BrokerList"));

const OfferingAdminEdit = lazy(
  () => import("@/areas/Offerings/OfferingAdminEdit"),
);
const OfferingClosing = lazy(() => import("@/areas/Offerings/OfferingClosing"));
const OfferingList = lazy(() => import("@/areas/Offerings/OfferingListAll"));
const OfferingShow = lazy(() => import("@/areas/Offerings/OfferingShow"));
const OfferingInvestors = lazy(
  () => import("@/areas/Offerings/OfferingInvestors"),
);
const OfferingEdit = lazy(() => import("@/areas/Offerings/OfferingEdit"));
const OfferingInvestments = lazy(
  () => import("@/areas/Offerings/OfferingInvestments"),
);
const OfferingTranche = lazy(() => import("@/areas/Offerings/OfferingTranche"));
const OfferingTranches = lazy(
  () => import("@/areas/Offerings/OfferingTranches"),
);

const InvestmentShow = lazy(() => import("@/areas/Investments/InvestmentShow"));
const InvestmentPayments = lazy(
  () => import("@/areas/Investments/InvestmentPayments"),
);
const InvestmentKyc = lazy(() => import("@/areas/Investments/InvestmentKyc"));
const InvestmentAgreements = lazy(
  () => import("@/areas/Investments/InvestmentAgreements"),
);
const InvestmentAccreditation = lazy(
  () => import("@/areas/Investments/InvestmentAccreditation"),
);
const InvestmentHistory = lazy(
  () => import("@/areas/Investments/InvestmentHistory"),
);

const Profile = lazy(() => import("@/areas/Profile/Profile"));

const IssuerList = lazy(() => import("@/areas/Issuers/IssuerList"));
const IssuerEdit = lazy(() => import("@/areas/Issuers/IssuerEdit"));
const IssuerOverview = lazy(() => import("@/areas/Issuers/IssuerOfferings"));

const LegacyRoutes = ({ isAdmin }) => {
  return (
    <Routes>
      <Route
        path="v2"
        element={
          <IssuerChakraProvider theme={V2Theme}>
            <Outlet />
          </IssuerChakraProvider>
        }>
        <Route
          element={
            <PublicRoute fallbackUrl="/v2/offerings">
              <AuthLayout />
            </PublicRoute>
          }>
          <Route path="login" element={<Login />} />
          <Route path="forgot" element={<Forgot />} />
          <Route path="mfa" element={<MFA />} />
          <Route path="reset/:id/:token" element={<Reset />} />
        </Route>

        <Route
          element={
            <PrivateRoute fallbackUrl="/v2/login">
              <AppLayout />
            </PrivateRoute>
          }>
          {/* Redirect old offering routes to new offerings (plural) routes */}
          <Route
            path="offering/*"
            element={<Navigate to="/v2/offerings" replace={true} />}
          />
          <Route
            path="issuer/*"
            element={<Navigate to="/v2/issuers" replace={true} />}
          />

          <Route path="offerings">
            <Route index element={<OfferingList />} />

            <Route path=":offeringId" element={<OfferingContextProvider />}>
              <Route element={<OfferingAreaContainer />}>
                <Route
                  index
                  path="investments"
                  element={<OfferingInvestments />}
                />
                <Route path="details" element={<OfferingShow />} />
                <Route path="edit" element={<OfferingEdit />} />
                <Route path="closing" element={<OfferingClosing />} />
                <Route path="tranches" element={<OfferingTranches />} />
                <Route path="profiles" element={<OfferingInvestors />} />
                <Route
                  path="tranches/:trancheId"
                  element={<OfferingTranche />}
                />
              </Route>
              <Route path="admin" element={<OfferingAdminEdit />} />
              <Route
                path="investments/:investmentId"
                element={<InvestmentContextProvider />}>
                <Route element={<InvestmentAreaContainer />}>
                  <Route
                    index
                    element={<Navigate to="./summary" replace={true} />}
                  />
                  <Route path="summary" element={<InvestmentShow />} />
                  <Route path="payments" element={<InvestmentPayments />} />
                  <Route path="kyc" element={<InvestmentKyc />} />
                  <Route path="agreements" element={<InvestmentAgreements />} />
                  <Route
                    path="accreditation"
                    element={<InvestmentAccreditation />}
                  />
                  <Route path="history" element={<InvestmentHistory />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="issuers" element={<IssuerContextProvider />}>
            <Route index element={<IssuerList />} />
            <Route path=":id" index element={<IssuerOverview />} />
            <Route path=":id/edit" element={<IssuerEdit />} />
          </Route>

          <Route path="brokers">
            <Route index element={<BrokerList />} />
          </Route>

          <Route path="account" element={<Profile />} />
          <Route path="vault" element={<VaultInvestors />} />
        </Route>
      </Route>

      <Route
        element={
          <IssuerChakraProvider theme={EquidefiTheme}>
            <PublicRoute fallbackUrl="/offering">
              <PublicLayout />
            </PublicRoute>
          </IssuerChakraProvider>
        }>
        <Route index element={<LoginLegacy />} />
        <Route path="mfa" element={<MFALegacy />} />
        <Route path="forgot" element={<ForgotLegacy />} />
        <Route path="reset/:id/:token" element={<ResetLegacy />} />
        <Route
          path="accept-invite/issuer/:token"
          element={<AcceptInvitation />}
        />
        <Route path="complete-profile/:token" element={<CompleteProfile />} />
      </Route>
      <Route
        element={
          <IssuerChakraProvider theme={EquidefiTheme}>
            <PrivateRoute>
              <AdminLayout />
            </PrivateRoute>
          </IssuerChakraProvider>
        }>
        <Route path="brokers">
          <Route index element={<BrokerListLegacy />} />
          <Route path="create" element={<BrokerEditorLegacy />} />
          <Route path=":id/broker" element={<BrokerEditorLegacy />} />
        </Route>

        <Route path="issuer">
          <Route index element={<IssuerListLegacy />} />
          {isAdmin && <Route path="create" element={<IssuerEditor />} />}
          <Route path=":id/users" element={<IssuerTeam />} />
          <Route path=":id/offerings" element={<IssuerOfferings />} />
          <Route path=":id/invitations" element={<Invitations />} />
          {isAdmin && <Route path=":id/edit" element={<IssuerEditor />} />}
          {isAdmin && <Route path=":id/roles" element={<Roles />} />}
        </Route>

        {isAdmin && <Route path="inquiries" element={<InquiriesList />} />}

        {isAdmin && <Route path="/vault" element={<VaultList />} />}

        <Route path="/vault/:vaultId" element={<VaultEdit />}>
          <Route path="overview" element={<VaultOverview />} />
          <Route path="profile" element={<VaultProfile />} />
          <Route path="address" element={<VaultAddress />} />
          <Route path="entity" element={<VaultEntity />} />
          <Route path="metadata" element={<VaultMetadata />} />
        </Route>

        <Route path="/invoices" element={<InvoiceList />} />
        <Route path="/accreditation" element={<AccreditationList />} />
        <Route path="/accreditation/:id" element={<InvestorDocuments />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/payment/success" element={<Success />} />
        <Route path="/invoice/:id" element={<Invoice />} />

        <Route path="offering">
          <Route index element={<OfferingListLegacy />} />
          <Route path="create" element={<OfferingCreate />} />

          <Route path=":id">
            <Route path="overview" element={<OfferingOverview />} />
            <Route path="edit" element={<OfferingCreate />} />
            <Route path="admin" element={<OfferingAdmin />} />
            <Route path="tranches">
              <Route index element={<OfferingTraunchesLegacy />} />
              <Route path=":trauncheId" element={<OfferingTrancheLegacy />} />
            </Route>
            <Route path="agreements" element={<OfferingAgreements />} />
            <Route path="wizard/*" element={<OfferingWizard />} />
            <Route path="investments" element={<OfferingInvestmentsLegacy />} />
            <Route
              path="investment/:investment_id/overview"
              element={<Investment />}
            />
            <Route path="brokers" element={<OfferingBrokers />} />
            <Route path="documents" element={<OfferingDocuments />} />
            <Route
              path="agreements_legacy"
              element={<OfferingAgreementsLegacy />}
            />
            <Route path="settings" element={<OfferingSettings />} />
          </Route>
        </Route>

        <Route path="profile" element={<MyProfile />} />
        <Route path="*" element={<MissingPage />} />
      </Route>
    </Routes>
  );
};

const AppRoutes = ({ isAdmin }) => {
  return (
    <BrowserRouter>
      {/* Uses a hook under the hook. Some nuance may be needed page-to-page */}
      <ScrollToTop />
      <Suspense fallback={<Spinner show />}>
        <LegacyRoutes isAdmin={isAdmin} />
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
