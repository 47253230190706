import { Button, type ButtonProps, Tooltip, useToast } from "@chakra-ui/react";
import type React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { isProduction } from "@/constants/config";
import { useInvestmentSignatures } from "@equidefi/portals/hooks/useAgreements";
import { useEmbeddedSignature } from "@equidefi/portals/hooks/useSignatures";
import { Icon } from "@equidefi/ui/icon";

type TCountersignButtonProps = ButtonProps & {
  investmentId?: string;
  isCountersignable?: boolean;
};

const useCountersignButton = (investmentId: string) => {
  const [closed, setClosed] = useState(false);

  const toast = useToast();
  const { data, isFetchedAfterMount, refetch } = useInvestmentSignatures(
    investmentId,
    {
      refetchOnMount: "always",
      refetchInterval: closed ? 2_500 : false,
    },
  );

  const counterSignature = useEmbeddedSignature({
    testMode: !isProduction(),
    skipDomainVerification: !isProduction(),
    onError: async () => {
      toast({ status: "success", description: "Countersigning failed" });
    },
    onCancel: async () => {
      toast({ status: "info", description: "Countersigning incomplete" });
    },
    onSign: async () => {
      setClosed(true);
      refetch();
    },
    onFinish: async () => {
      toast({ status: "success", description: "Countersigning completed!" });
      refetch();
    },
  });

  const {
    can_not_countersign_reason: reason,
    countersigner,
    is_countersigner: isCountersigner,
    is_countersigned: isCountersigned,
    is_countersignable: isCountersignable,
    investment_agreement: agreement,
  } = data ?? {};

  // biome-ignore lint/correctness/useExhaustiveDependencies: Trigger effect when `closed` changes, too
  useEffect(() => {
    if (isCountersigned) setClosed(false);
  }, [closed, isCountersigned]);

  const countersignButtonShow = !!agreement;

  const countersignButtonEnabled = useMemo(
    () => isCountersigner && isCountersignable,
    [isCountersigner, isCountersignable],
  );

  const onCountersignClick = useCallback(async () => {
    try {
      await counterSignature.start(agreement?.id, countersigner?.signature_id);
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description: "Countersigning could not be started.",
      });
    }
  }, [agreement?.id, counterSignature, countersigner?.signature_id, toast]);

  return {
    show: countersignButtonShow || isFetchedAfterMount,
    reason,
    isDisabled: !countersignButtonEnabled,
    isLoading: counterSignature.isLoading,
    tooltip: reason,
    agreement,
    countersigner,
    onClick: onCountersignClick,
    isCountersigner,
    isCountersignable,
    isCountersigned,
  };
};

const CountersignButton: React.FC<TCountersignButtonProps> = ({
  investmentId,
  ...props
}) => {
  const { show, isDisabled, isLoading, tooltip, onClick, isCountersigned } =
    useCountersignButton(investmentId as string);

  if (!show) return null;

  if (isCountersigned) {
    return (
      <Button rightIcon={<Icon.Check size="1em" />} isDisabled {...props}>
        Countersigned
      </Button>
    );
  }

  if (isDisabled) {
    return (
      <Tooltip hasArrow placement="top-start" label={tooltip}>
        <Button isDisabled {...props}>
          Countersign
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button onClick={onClick} isLoading={isLoading} {...props}>
      Countersign
    </Button>
  );
};

export const CountersignButtonV2: React.FC<TCountersignButtonProps> = ({
  investmentId,
  ...props
}) => {
  const { show, isDisabled, isLoading, onClick, isCountersigned } =
    useCountersignButton(investmentId as string);

  if (!show) return null;

  if (isCountersigned) {
    return (
      <Button rightIcon={<Icon.Check size="1em" />} isDisabled {...props}>
        Countersigned
      </Button>
    );
  }

  return (
    <Button
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isDisabled}
      {...props}>
      Countersign
    </Button>
  );
};
export default CountersignButton;
