import {
	Button,
	type ButtonProps,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";

import { useCancelInvestmentAgreement } from "@equidefi/portals/hooks/useSignatures";

import InvestmentVoidModal, {
	type TVoidFormValues,
} from "@/areas/Investments/shared/InvestmentVoidModal";
import type { TInvestmentAgreementDTO } from "@/types/dto";
import { extractErrorMessage } from "@equidefi/portals";

type TVoidAgreementButtonProps = ButtonProps & {
	agreement: TInvestmentAgreementDTO;
	onVoided?: () => void;
};

const VoidAgreementButton: React.FC<TVoidAgreementButtonProps> = ({
	agreement,
	onVoided = () => {},
	...rest
}) => {
	const cancelAgreement = useCancelInvestmentAgreement();
	const voidModal = useDisclosure();

	const agreementCompleted = agreement?.status === "COMPLETED";
	const actionType = agreementCompleted ? "Void" : "Cancel";

	const handleClickVoidAgreement = async () => {
		const status = await swal({
			title: `${actionType} Agreement`,
			text: `Are you sure you want to ${actionType.toLowerCase()} the current agreement?`,
			buttons: ["Dismiss", "Yes"],
			icon: "error",
			dangerMode: true,
		});

		if (status) {
			voidModal.onOpen();
		}
	};

	const toast = useToast();

	const handleConfirmVoid = async ({ reason }: TVoidFormValues) => {
		try {
			await cancelAgreement.mutateAsync({
				investmentId: agreement.investment_id,
				investmentAgreementId: agreement.id,
				reason: reason,
			});
			voidModal.onClose();
			toast({
				status: "success",
				description: `The agreement has been ${actionType.toLowerCase()}ed and investors have received instructions to continue signing agreements.`,
			});
			onVoided();
		} catch (e: unknown) {
			console.error(e);
			toast({
				status: "error",
				description: extractErrorMessage(
					e,
					`Sorry, we could not ${actionType.toLowerCase()} this agreement. Our team has been notified`,
				),
			});
		}
	};

	if (!agreement) {
		return null;
	}

	return (
		<>
			<Button
				isLoading={cancelAgreement.isLoading}
				onClick={handleClickVoidAgreement}
				colorScheme="red"
				{...rest}
			>
				{`${actionType} Agreement`}
			</Button>

			<InvestmentVoidModal
				actionType={actionType}
				isOpen={voidModal.isOpen}
				onClose={voidModal.onToggle}
				isLoading={cancelAgreement.isLoading}
				onSubmit={handleConfirmVoid}
			/>
		</>
	);
};

export default VoidAgreementButton;
