import {
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Flex,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Heading, TooltipTime } from "@equidefi/ui";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

import { CanEdit } from "@/components/permissions/Can";
import { TInvestmentDTO } from "@/types/dto";
import { EquidefiMarkdown } from "@equidefi/portals/components/Markdown";
import {
  useInvestmentNoteCreate,
  useInvestmentNotes,
} from "@equidefi/portals/hooks/useNotes";
import { ExpandableInput } from "@equidefi/ui/forms/Input";

export default function InvestmentNotes({
  investment,
}: { investment: TInvestmentDTO }) {
  const { data } = useInvestmentNotes(investment?.id);
  const createNote = useInvestmentNoteCreate(investment?.id);
  const toast = useToast();

  const notes = useMemo(() => data?.data ?? [], [data]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
    reset,
  } = useForm({
    defaultValues: {
      content: "",
    },
  });

  if (!investment?.id) return null;

  const handleOnSubmit = async (data: { content: string }) => {
    await createNote.mutateAsync(data);
    reset();

    try {
      toast({
        status: "success",
        description: "Note added!",
      });
    } catch (e) {
      toast({
        status: "error",
        description: "Sorry. Note could not be saved",
      });
    }
  };

  return (
    <CanEdit userRole="editor" offeringId={investment?.offering_id}>
      <Card w="full">
        <CardBody>
          <Heading textStyle="h2" fontSize="xl" fontWeight="bold" mb={4}>
            Notes
          </Heading>
          <VStack spacing={4} align="stretch">
            {notes.map((note) => (
              <Box key={note.id}>
                <Box mb={8}>
                  <Flex gap={2} mb={-3}>
                    <Text fontWeight="medium">{note.author.full_name}</Text>
                    <Text color="gray.500" fontSize="sm">
                      ·
                    </Text>
                    <Text color="gray.500" fontSize="sm">
                      on{" "}
                      <TooltipTime
                        datetime={note.create_date}
                        format="LL"
                        tooltip="llll"
                        tooltipProps={{ placement: "right" }}
                      />
                    </Text>
                  </Flex>
                  <EquidefiMarkdown>{note.content}</EquidefiMarkdown>
                </Box>
                <Divider border="0" h="1px" mt={-4} mb={0} />
              </Box>
            ))}
            <Box as="form" onSubmit={handleSubmit(handleOnSubmit)}>
              <Flex gap={2} mb={2}>
                <ExpandableInput
                  label="Note"
                  isRequired
                  labelProps={{ display: "none" }}
                  {...register("content", { required: true })}
                  placeholder="Add a note..."
                  rows={1}
                  value={watch("content")}
                />
                <Button
                  isLoading={createNote.isLoading}
                  isDisabled={!isValid}
                  type="submit"
                  mt="1"
                  colorScheme="gray">
                  Add Note
                </Button>
              </Flex>
              <Text fontSize="sm" color="gray.500">
                Anyone with viewer access to the offering is able to see these
                notes. Investors are not able to see these notes. Notes can not
                be edited or deleted.
              </Text>
            </Box>
          </VStack>
        </CardBody>
      </Card>
    </CanEdit>
  );
}
