import VaultClient from "@equidefi/portals/clients/VaultClient";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useQuery } from "@tanstack/react-query";

/**
 * @param {{ search?: string, status?: string, offeringId?: string, page?: number }} options
 * @param {import("@tanstack/react-query").UseQueryOptions} options
 */
export const useSearchVault = ({ search = undefined, status, offeringId = undefined, page = 1 } = {}, options = {}) => {
  const vaultApi = useApi(VaultClient);

  return useQuery(
    ["vault", "search", { search, status, offeringId, page }],
    () => vaultApi.search({ search, status, offering_id: offeringId, page }),
    {
      keepPreviousData: true,
      ...options,
    },
  );
};
