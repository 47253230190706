import {
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router";

import { useAuth } from "@equidefi/portals/hooks/useAuth";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useIsMobile } from "@equidefi/portals/hooks/useIsMobile";
import { Icon } from "@equidefi/ui/icon";

import { EnvironmentCard } from "@/components/EnvironmentCard";
import Logo from "@public/img/equidefi-logo-color-black.svg";

export const NavBar = ({ children }) => {
  const toast = useToast();
  const menu = useDisclosure();
  const { clearToken } = useAuth();
  const isMobile = useIsMobile("lg");
  const { data: user } = useCurrentUser();
  const MenuIcon = menu.isOpen ? Icon.X : Icon.Menu;

  return (
    <>
      <Flex
        position={{ base: "sticky", lg: "fixed" }}
        direction="column"
        w={{ base: "full", lg: "190px" }}
        zIndex="2"
        minH="full">
        <HStack p="5" w="full">
          {isMobile && (
            <IconButton
              aria-label={menu.isOpen ? "Hide Menu" : "Open Menu"}
              icon={<MenuIcon size="1.5em" />}
              variant="unstyled"
              onClick={menu.onToggle}
            />
          )}
          <Image src={Logo} w="150" mx="auto" />
          {isMobile && <Box w="44px" h="44px" />}
        </HStack>

        <EnvironmentCard />

        {(menu.isOpen || !isMobile) && (
          <>
            <VStack mt={{ base: 0, lg: 100 }} gap="2.5" minW="full">
              <NavItem to={"/v2/offerings"} Icon={Icon.Folder}>
                Offerings
              </NavItem>
              <NavItem to={"/v2/issuers"} Icon={Icon.Briefcase}>
                Issuers
              </NavItem>
              <NavItem to={"/v2/account"} Icon={Icon.User}>
                Account
              </NavItem>
              <NavItem to={"/v2/vault"} Icon={Icon.Users}>
                Investors
              </NavItem>
              <NavItem to={"/v2/brokers"} Icon={Icon.TrendingUp}>
                Brokers
              </NavItem>
            </VStack>

            <VStack mt="auto" mb={4} minW="full">
              <NavItem to={"/offering"} Icon={Icon.Folder}>
                Back to V1
              </NavItem>
              <Divider m="0" />
              <VStack align="center" justify="space-between" w="full">
                <Text fontSize="md" fontWeight="bold" color="black" my={0}>
                  {user?.full_name}
                </Text>
                <Flex
                  mt={-2}
                  align="center"
                  cursor="pointer"
                  color="gray.500"
                  onClick={() => {
                    clearToken();
                    toast({
                      status: "success",
                      description: "Logged out",
                    });
                  }}>
                  <Text my={0} mr={4} fontSize="md">
                    Log Out
                  </Text>
                  <Icon.LogOut size="1.2em" />
                </Flex>
              </VStack>
            </VStack>
          </>
        )}
        {isMobile && <Divider m="0" />}
      </Flex>
      <Box ml={{ base: 0, lg: "190" }} w="full">
        {children}
      </Box>
    </>
  );
};

const NavItem = ({ onClick = () => {}, to, children, Icon, ...props }) => {
  const Action = to ? NavLink : Box;
  const { pathname } = useLocation();
  const isCurrentPage = to && pathname === to;

  return (
    <Box
      as={Action}
      onClick={onClick}
      to={to}
      cursor="pointer"
      w="full"
      color={isCurrentPage && "purple.500"}
      fontWeight={isCurrentPage && "700"}
      {...props}>
      <Flex gap={5} opacity="90%">
        <Box
          w="2"
          bgColor={isCurrentPage && "purple.500"}
          borderRightRadius="5px"
        />
        <Flex my={2} gap={2.5} lineHeight={"24px"}>
          <Box as={Icon} opacity="90%" size="1.6em" />
          {children}
        </Flex>
      </Flex>
    </Box>
  );
};
