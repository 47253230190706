import { useIssuer } from "@/hooks/useIssuers";
import type { UseQueryResult } from "@tanstack/react-query";
import { createContext, useContext } from "react";
import { Outlet, useParams } from "react-router";
import type { Issuer } from "./types";

export type { Issuer } from "./types";

type IssuerContextType = UseQueryResult<Issuer, unknown>;

const IssuerContext = createContext<IssuerContextType>({} as IssuerContextType);

export default function IssuerContextProvider(): JSX.Element {
  const { id: issuerId } = useParams();
  const query = useIssuer(issuerId);

  return (
    <IssuerContext.Provider value={query as IssuerContextType}>
      <Outlet />
    </IssuerContext.Provider>
  );
}

export const useIssuerContext = () => {
  const { data: issuer, ...rest } = useContext(IssuerContext);
  return { issuer, ...rest };
};
