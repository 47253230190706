import { Box, Flex } from "@chakra-ui/react";

import {
  HeaderAvatar,
  HeaderBreadcrumb,
  HeaderCard,
  HeaderTitle,
} from "@/components/nav/HeaderCard";
import { TInvestmentDTO } from "@/types/dto";

interface InvestmentHeaderCardProps {
  investment: TInvestmentDTO;
}

export function InvestmentHeaderCard({
  investment,
}: InvestmentHeaderCardProps): React.JSX.Element {
  const { offering, issuer, vault } = investment || {};
  const { name: issuerName, photo_url: issuerPhotoUrl } = issuer || {};

  return (
    <HeaderCard>
      <Flex align="center" gap={3}>
        <HeaderAvatar url={issuerPhotoUrl} alt={`${issuerName} Logo`} />
        <Box>
          <HeaderBreadcrumb
            crumbs={{
              [issuerName]: `/v2/issuers/${offering?.issuer_id}`,
              [offering?.name]: `/v2/offerings/${offering?.id}/investments`,
            }}
          />
          <HeaderTitle mb={0}>
            Investment by {investment.vault?.display_name ?? "N/A"}
          </HeaderTitle>
        </Box>
      </Flex>
    </HeaderCard>
  );
}
