import { Tooltip, type TooltipProps, chakra } from "@chakra-ui/react";
import moment from "moment";
import type React from "react";

export type LooseAutocomplete<T extends string> = T | Omit<string, T>;
type TimeFormat = LooseAutocomplete<"LT" | "LL" | "LLL" | "llll">;

const formats = new Map<TimeFormat, string>([
  ["LL", "LL"],
  ["LT", "LT"],
  ["LLL", "LLL"],
  ["llll", "llll"],
]);

type TooltipTimeProps = {
  datetime: moment.MomentInput;
  format: TimeFormat;
  tooltip?: TimeFormat;
  tooltipProps?: Omit<TooltipProps, "children">;
};

export const TooltipTime: React.FC<TooltipTimeProps> = ({
  datetime,
  format,
  tooltip,
  tooltipProps = {},
}) => {
  const time = moment(datetime).clone();

  const mainFormat = formats.has(format) ? formats.get(format) : format;
  const tooltipFormat =
    tooltip && formats.has(tooltip)
      ? formats.get(tooltip)
      : (tooltip as TimeFormat);

  return (
    <Tooltip
      isDisabled={!tooltip}
      label={tooltip ? time.format(tooltipFormat as string) : ""}
      {...tooltipProps}>
      <chakra.time dateTime={time.toISOString()}>
        {time.format(mainFormat as string)}
      </chakra.time>
    </Tooltip>
  );
};
