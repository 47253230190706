import { Box, Alert, AlertIcon, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router";

type StatusType = "warning" | "success" | "info" | "error";

const statusColors: Record<StatusType, string> = {
  warning: "equidefi.yellow",
  success: "equidefi.green",
  info: "equidefi.blue",
  error: "equidefi.red",
};

interface StatusBannerProps {
  status?: StatusType;
  title?: string | null;
  description?: string | null;
  linkTarget?: string | null;
  hideIcon?: boolean | false;
  centered?: boolean | false;
  uppercase?: boolean | false;
}

export const StatusBanner = ({
  status = "warning",
  title,
  description,
  linkTarget = null,
  hideIcon = false,
  centered = false,
  uppercase = false,
  ...props
}: StatusBannerProps) => {
  const navigate = useNavigate();

  const alert = (
    <Alert
      status={status}
      borderRadius="lg"
      border="1px solid"
      borderColor={statusColors[status]}
      boxShadow="sm"
      {...props}>
      {!hideIcon && <AlertIcon />}
      <Box textAlign={centered ? "center" : "left"} width="100%">
        {title && (
          <Text
            as="span"
            textStyle="body2"
            fontWeight="bold"
            display="block"
            textTransform={uppercase ? "uppercase" : "none"}>
            {title}
          </Text>
        )}
        {description && (
          <Text as="span" textStyle="body2" textTransform={uppercase ? "uppercase" : "none"}>
            {description.split("\n\n").map((paragraph: string, index: number) =>
              paragraph ? (
                <Text as="p" key={index} mb={index < description.split("\n\n").length - 1 ? 4 : 0}>
                  {paragraph}
                </Text>
              ) : null,
            )}
          </Text>
        )}
      </Box>
    </Alert>
  );

  if (linkTarget) {
    return (
      <Box onClick={() => navigate(linkTarget)} cursor="pointer" _hover={{ opacity: 0.8 }} transition="opacity 0.2s">
        {alert}
      </Box>
    );
  }

  return alert;
};
