/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Tag,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
  useToken,
} from "@chakra-ui/react";
import moment from "moment";
import { useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { Link, useParams } from "react-router";

import { InvestmentEventHistory } from "@equidefi/portals/components/investments/EventHistory";
import { StatusBadge } from "@equidefi/portals/components/investments/StatusBadge";
import { useInvestmentSignatures } from "@equidefi/portals/hooks/useAgreements";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import { useUpdateInvestment } from "@equidefi/portals/hooks/useInvestments";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { offeringIsTypeRegA } from "@equidefi/shared";
import { capitalizeEveryWord } from "@equidefi/shared/helpers/string";
import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import InfoCard from "@/components/card";
import InvestmentSignatures from "@/components/investments/Agreements/InvestmentSignatures";
import InvestmentAccreditation from "@/components/investments/InvestmentAccreditation/InvestmentAccreditation";
import InvestmentInquiry from "@/components/investments/InvestmentInquiry";
import InvestmentPayments from "@/components/investments/InvestmentPayments";
import InvestorProfile from "@/components/investments/InvestorProfile";
import Spinner from "@/components/spinner";
import {
  isAdmin,
  isIssuerOwner,
  isIssuerViewer,
  isOfferingEditor,
  isOfferingOwner,
} from "@/helpers";
import { useInvestment, usePokeInvestor } from "@/hooks/investments";
import { useOfferingFull } from "@/hooks/useOfferings";
import Header from "@/layouts/header";

// This is referencing to the V2 InvestmentNotes component
import InvestmentNotes from "@/areas/Investments/components/InvestmentNotes";

import { V2Button } from "@/components/V2Button";
import UpdateStatusStepModal from "@/components/investments/modals/UpdateStatusStepModal";
import { EntityTypeIcon } from "@equidefi/portals/components/EntityTypeIcon";
import { EditAmountModal } from "./EditAmountModal";

const NO_POKE_INVESTOR = ["APPROVED", "COMPLETED", "CLOSED", "REJECTED"];

const InvestmentOverview = (props) => {
  const { id, investment_id } = useParams();
  const { data: full } = useOfferingFull(id);
  const { data: user } = useCurrentUser();
  const toast = useToast();

  const { data: investmentData, refetch: refetchInvestment } = useInvestment(
    investment_id,
    {
      refetchOnMount: "always",
    },
  );
  const { data: signatureData } = useInvestmentSignatures(investment_id, {
    refetchOnMount: "always",
  });
  useDocumentTitle([investmentData?.user?.full_name, full?.offering?.name]);

  const updateStatusModal = useDisclosure(false);
  const [spinner, showSpinner] = useState(false);
  const amountModal = useDisclosure();

  const [editInvestment, setEditInvestment] = useState(false);
  const [blue] = useToken("colors", ["equidefi.blue"]);

  const updateInvestment = useUpdateInvestment(investment_id, {
    onSettled: () => {
      setEditInvestment(false);
      showSpinner(false);
    },
    onSuccess: () => {
      toast({
        status: "success",
        description: `Successfully updated investment amount`,
      });
    },
    onError: () => {
      toast({
        status: "error",
        description: "Something went wrong",
      });
    },
  });

  const pokeInvestor = usePokeInvestor(investment_id, {
    onSettled: () => showSpinner(false),
    onSuccess: () => {
      toast({
        status: "success",
        description: `Sent a reminder to ${investmentData?.user?.email}`,
      });
    },
    onError: () => {
      toast({
        status: "error",
        description: "Something went wrong",
      });
    },
  });

  const canEditPayment = useMemo(
    () =>
      isAdmin(user) ||
      isOfferingOwner(user, full?.offering?.id) ||
      isIssuerOwner(user, full?.offering?.issuer_id),
    [user, full],
  );

  const canEdit = useMemo(
    () => canEditPayment || isOfferingEditor(user, full?.offering?.id),
    [user, full],
  );

  const paid = useMemo(() => {
    const succeededPayments =
      investmentData?.payments
        ?.filter((p) => p.status === "succeeded")
        ?.reduce((memo, payment) => memo + Number(payment.amount), 0) ?? 0;

    return Math.max(succeededPayments, 0);
  }, [investmentData]);

  const balance = useMemo(() => {
    if (!investmentData) return 0;

    const amount = investmentData?.approved_amount ?? investmentData?.amount;

    return Number(amount) - paid;
  }, [investmentData, paid]);

  const isRegA = useMemo(() => offeringIsTypeRegA(full?.offering), [full]);

  const canCountersign = useMemo(() => {
    return (
      investmentData?.status !== "REJECTED" &&
      Number(balance) === 0 &&
      (isRegA ? true : signatureData?.is_countersignable)
    );
  }, [
    investmentData?.status,
    investmentData?.event_history,
    balance,
    full,
    investment_id,
    signatureData,
  ]);

  const handleResend = async () => {
    showSpinner(true);
    await pokeInvestor.mutateAsync();
  };

  const saveEditedAmount = async (data) => {
    investmentData.approved_amount = data.amount;
    investmentData.approved_quantity = data.quantity;
    investmentData.edited = true;
    showSpinner(true);

    amountModal.onClose();
    await updateInvestment.mutateAsync({
      amount: data.amount,
      quantity: data.quantity,
    });
    setEditInvestment(false);
    showSpinner(false);
  };

  const canEditInvestmentAmount = useMemo(() => {
    return !signatureData?.is_countersigned && isAdmin(user);
  }, [signatureData?.is_countersigned, user]);

  return (
    <>
      <Header
        id={id}
        title={investmentData?.offering?.name}
        name={
          <HStack wrap="nowrap">
            <Box as="span">{investmentData?.vault?.display_name}</Box>
            <Box color="gray.500">
              <Tooltip
                label={capitalizeEveryWord(
                  investmentData?.vault?.entity_type ?? "Unknown Type",
                )}
                isDisabled={!investmentData}>
                <EntityTypeIcon type={investmentData?.vault?.entity_type} />
              </Tooltip>
            </Box>
          </HStack>
        }>
        <Flex gap="2">
          {isAdmin(user) && (
            <V2Button to={`/v2/offerings/${id}/investments/${investment_id}`} />
          )}
        </Flex>
      </Header>
      <div className="container-fluid">
        <Spinner show={spinner}>
          <div className="row">
            <InfoCard
              editable={
                isIssuerOwner(user, investmentData?.issuer_id) ||
                isOfferingOwner(user, investmentData?.offering_id) ||
                isAdmin(user)
              }
              onClick={updateStatusModal.onOpen}
              title="Status">
              <StatusBadge
                as={Tag}
                fontSize="lg"
                action={investmentData?.status}
              />
            </InfoCard>
            {["COMPLETED", "CLOSED"].includes(investmentData?.status) ||
            !!investmentData?.offering_tranche_id ? (
              <InfoCard title="Closing Tranche">
                <div className="d-flex">
                  <Text fontSize="13px" mb="0">
                    {investmentData?.offering_tranche_id
                      ? `${moment(
                          investmentData?.offering_traunche?.finalized_date,
                        ).format("MMM Do, yyyy")}`
                      : "Ready to Close"}
                  </Text>
                  {!isIssuerViewer(user, investmentData?.issuer_id) && (
                    <Link
                      to={
                        investmentData?.offering_tranche_id
                          ? `/offering/${investmentData?.offering_id}/tranches/${investmentData?.offering_tranche_id}`
                          : `/offering/${investmentData?.offering_id}/tranches`
                      }>
                      <IconButton
                        icon={<Icon.ExternalLink size="1em" />}
                        color="equidefi.blue"
                        variant="unstyled"
                        aria-label="External link"
                        ml={2}
                        mb="1"
                        minWidth="auto"
                        height="auto"
                      />
                    </Link>
                  )}
                </div>
              </InfoCard>
            ) : (
              <InfoCard
                editable={
                  isIssuerOwner(user, investmentData?.issuer_id) ||
                  isOfferingOwner(user, investmentData?.offering_id) ||
                  isAdmin(user)
                }
                onClick={updateStatusModal.onOpen}
                title="Investment Current Step">
                <Tag fontSize="lg" textTransform="uppercase" colorScheme="gray">
                  {investmentData?.next ?? "None"}
                </Tag>
              </InfoCard>
            )}
            <InfoCard title="Total Subscription">
              {investmentData?.approved_quantity &&
              investmentData?.approved_quantity !== investmentData?.quantity ? (
                <>
                  <span className="me-2">
                    <NumberFormat
                      value={investmentData?.approved_quantity}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </span>
                  <del className="small text-muted">
                    <NumberFormat
                      value={investmentData?.quantity}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </del>
                </>
              ) : (
                <NumberFormat
                  value={investmentData?.quantity}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={0}
                />
              )}
            </InfoCard>
            <InfoCard
              title="Amount"
              editable={canEditInvestmentAmount}
              onClick={() => amountModal.onOpen()}>
              {investmentData?.approved_amount &&
              investmentData?.amount !== investmentData?.approved_amount &&
              !editInvestment ? (
                <>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span className="me-2">
                        <NumberFormat
                          value={investmentData?.approved_amount}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={0}
                          prefix="$"
                        />
                      </span>
                      <del className="small text-muted">
                        <NumberFormat
                          value={investmentData?.amount}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={0}
                          prefix="$"
                        />
                      </del>
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-between">
                  <NumberFormat
                    value={investmentData?.amount}
                    displayType="text"
                    thousandSeparator={true}
                    decimalScale={0}
                    prefix="$"
                  />
                </div>
              )}
            </InfoCard>
            <InfoCard title="Total Payments">
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={paid}
                prefix="$"
              />
            </InfoCard>
            <InfoCard title="Total Due">
              <NumberFormat
                displayType="text"
                thousandSeparator
                value={balance}
                prefix="$"
              />
            </InfoCard>
            <InfoCard title="Expedited Investment">
              <Flex justify="center" align="center" gap={2}>
                {investmentData?.expedited && (
                  <Tooltip label="This is a repeat investment from this investor using the same profile as their originating investment.">
                    <Icon.FastForward
                      aria-hidden="true"
                      color={blue}
                      size="1em"
                    />
                  </Tooltip>
                )}
              </Flex>
            </InfoCard>
          </div>

          <div className="row">
            <div className="col">
              <InvestmentSignatures
                investmentId={investment_id}
                investment={investmentData}
                isCountersignable={canCountersign}
                onCountersign={refetchInvestment}
              />

              <InvestmentPayments
                canEdit={canEditPayment}
                canRefund={canEditPayment}
                investment={investmentData}
                offering={full?.offering}
                totalPaid={paid}
                totalBalance={balance}
                onUpdatePayments={() => {
                  refetchInvestment();
                }}
              />

              <InvestmentInquiry />
              <InvestmentAccreditation
                investment={investmentData}
                offering={full?.offering}
                user={user}
              />

              <InvestmentNotes investment={investmentData} />
            </div>

            <Box className="col col-md-4">
              <VStack w="full">
                <InvestorProfile
                  user={user}
                  investment={investmentData}
                  onSubmit={() => refetchInvestment()}
                />
                <InvestmentEventHistory
                  investment={investmentData}
                  canPoke={
                    full?.offering?.status === "Active" &&
                    !NO_POKE_INVESTOR.includes(investmentData?.status) &&
                    canEdit
                  }
                  onPoke={() => handleResend()}
                />
              </VStack>
            </Box>
          </div>
        </Spinner>
      </div>

      <EditAmountModal
        offering={full?.offering}
        investment={investmentData}
        onSubmit={saveEditedAmount}
        modal={amountModal}
      />
      {investmentData && (
        <UpdateStatusStepModal
          investment={investmentData}
          isOpen={updateStatusModal.isOpen}
          onHide={() => updateStatusModal.onClose()}
          onSubmit={() => {
            updateStatusModal.onClose();
            refetchInvestment();
          }}
          show={updateStatusModal.isOpen}
        />
      )}
    </>
  );
};

export default InvestmentOverview;
